

export const initializeFreshwork = (locale = 'sv') => {
  const s1 = document.createElement('script'),
    s0 = document.getElementsByTagName('script')[0];
  s1.src = '//eu.fw-cdn.com/11580588/430928.js';
  s1.setAttribute('chat', 'true');
  s1.setAttribute('widgetId', 'ade978c6-a5f1-465a-812c-46db49ecfc49');
  s0.parentNode.insertBefore(s1, s0);
};

export const initGoogleTagManager = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WMVJSJF');
}

export const initFbScript = () => {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  fbq('init', '1178336678844309');
  fbq('track', 'PageView');
}
