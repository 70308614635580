import { register, init } from 'svelte-i18n';
import { LANGUAGE } from './constants';

register('en', () => import('./locales/en.json'));
register('sv', () => import('./locales/sv.json'));
register('fi', () => import('./locales/fi.json'));
register('no', () => import('./locales/no.json'));


export const initI18n = (language = LANGUAGE) => {
  init({
    fallbackLocale: language,
    initialLocale: language,
  });  
}

