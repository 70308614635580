import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsightsStarted = false;

function startAppInsights({ instrumentationKey, endpointUrl }) {
  if (!instrumentationKey) return;

  appInsightsStarted = true;

  console.log('AI');
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      enableAutoRouteTracking: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { startAppInsights };
