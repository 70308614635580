<script>
    // TODO: use system settings as default
    import { onMount } from 'svelte';
    import { LANGUAGE_STORAGE_KEY } from '../constants';
    import { getLanguage } from '../utils/prismicHelpers';
    import { initI18n } from '../i18n';
    import Select from 'svelte-select';
    

    export let systemLanguage;

    let language;
    
    let items = [
        {value: 'sv', label: '🇸🇪'},
        {value: 'en', label: '🇬🇧'},
        {value: 'fi', label: '🇫🇮'},
        {value: 'no', label: '🇳🇴'},
  ];

  function handleSelect(event) {
        localStorage.setItem(LANGUAGE_STORAGE_KEY, event.detail.value);
        window.location.reload();
  }
    
    onMount(() => {
        if(!localStorage.getItem(LANGUAGE_STORAGE_KEY)) {
            localStorage.setItem(LANGUAGE_STORAGE_KEY, systemLanguage)
            language = systemLanguage;
        } else {
            language = localStorage.getItem(LANGUAGE_STORAGE_KEY);
        }
    });

    $: {
        if(typeof window !== 'undefined' && language) {
            initI18n(getLanguage(language));
        }
    }
</script>

<div class="language-select">
    {#if language}
        <Select
            {items}
            value={items.find(i => i.value === language)}
            isClearable={false}
            isSearchable={false}
            showChevron={false}
            showIndicator={true}
            
            on:select={handleSelect}
        />
    {/if}
</div>

<style>
    .language-select {
        --height: 52px;
        --indicatorTop: 14px;
        --fontSize: 24px;
    }
    .language-select :global(.selectedItem) {
        font-size: var(--fontSize);
        padding: 0 5px;
    }
    .language-select :global(.listItem) {
        font-size: var(--fontSize);
        /* padding: 0; */
    }
    .language-select :global(.indicator) {
        display: none;
    }
    @media (max-width: 900px) {
        .language-select {
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px 12px;
        }
    }
</style>
