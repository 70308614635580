<script>
  import { _ } from 'svelte-i18n';
  import BookDemo from '../components/BookDemo.svelte';
  import Button from '../components/Button.svelte';

  let openModal;
  let isModalVisible;
</script>

<Button
  on:click={openModal}
  wiggle={!isModalVisible}
  floating
  large
  primary
  uppercase
>
  {$_('bookDemo.button')}
</Button>

<BookDemo bind:open={openModal} bind:visible={isModalVisible} />
