<script>
  import { _ } from 'svelte-i18n';
  import { getYear } from 'date-fns';
  import BookDemoWithButton from './BookDemoWithButton.svelte';
  import { page } from '$app/stores';
  import { pagesWithNoBookDemo } from '../lib/common'
</script>

<style>
  .footer {
    border-top: 1px solid #d4d4d4;
    background-color: #f0f2f4;
    padding: 37px 20px 72px;
    color: #656565;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
  .content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  .footer__subheader {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .hidden {
    display: none;
  }
  .divider {
    margin-bottom: 20px;
  }
  .social-icon {
    font-size: 27px;
    margin-right: 6px;
    transition: color 0.3s ease;
  }
  .social-icon:hover {
    color: #f57224;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li {
    display: block;
    margin-bottom: 8px;
  }
  a,
  a:visited {
    color: #656565;
  }
  .download-button-wrapper {
    margin-right: 9px;
  }
  .download-button {
    height: 37px;
  }

  @media (max-width: 600px) {
    ul {
      margin-bottom: 2rem;
    }
    .content {
      flex-direction: column;
    }
  }

  .barndiabetesfonden {
    height: 90px;
    width: auto;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .ot-sdk-show-settings {
    display: block;
    padding: 0px!important;
    font-family: 'Poppins', sans-serif!important;
    border: none!important;
    color: #656565!important;
    font-size: 14px!important;
    line-height: 21px!important;
  }
  .ot-sdk-show-settings:hover {
    background: transparent!important;
  }
</style>
<footer class="footer">
  <div class="content">
    <div class="footer__col">
      <div class="footer__subheader">{$_('aboutUs.title')}</div>
      <ul>
        <li><a href="/press">{$_('press.title')}</a></li>
        <li><a href="/#contact">{$_('contactUs.title')}</a></li>
        <li><a href="/privacy">{$_('privacyStatement.title')}</a></li>
        <li><a href="/user-agreement">{$_('agreement')}</a></li>
        <li><a href="/terms-for-user-account">{$_('termsForUserAccount')}</a></li>
        <li>
          <a
            class="social-icon social-icon--youtube"
            href={$_('links.youtube', {
              default:
                'https://www.youtube.com/channel/UCk3yXTAVUKRjJPFh2IbYGfA',
            })}
            title="YouTube"
          >
            <i class="fab fa-youtube" />
          </a>
          <a
            class="social-icon social-icon--instagram"
            href={$_('links.instagram', {
              default: 'https://www.instagram.com/bokameraab/',
            })}
            title="Instagram"
          >
            <i class="fab fa-instagram" />
          </a>
          <a
            class="social-icon social-icon--linkedin"
            href={$_('links.linkedin', {
              default: 'https://www.linkedin.com/company/bokamera/',
            })}
            title="LinkedIn"
          >
            <i class="fab fa-linkedin" />
          </a>
          <a
            class="social-icon social-icon--facebook"
            href={$_('links.facebook', {
              default: 'https://www.facebook.com/Bokamera',
            })}
            title="Facebook"
          >
            <i class="fab fa-facebook" />
          </a>
        </li>
      </ul>
    </div>
    <div class="footer__col">
      <div class="footer__subheader">{$_('theProduct')}</div>
      <ul>
        <li><a href="/for-developers">{$_('menu.forDevelopers')}</a></li>
        <li><a href="/#pricing">{$_('priceInformation')}</a></li>
        <li><a href="/articles">Blogg</a></li>
      </ul>
    </div>
    <div class="footer__col">
      <div class="footer__subheader">{$_('mobileApps')}</div>
      <a
        href="https://apps.apple.com/se/app/bokamera/id738027900"
        rel="noopener"
        class="download-button-wrapper"
      >
        <img
          src="/download-app-store.svg"
          alt="AppStore"
          class="download-button"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.oneclick.bokamera&hl=sv"
        rel="noopener"
        class="download-button-wrapper"
      >
        <img
          src="/download-google-play.svg"
          alt="Google Play"
          class="download-button"
        />
      </a>
    </div>
    <div class="footer__col">
      <div class="footer__subheader">{$_('adminLogin')}</div>
      <ul>
        <li>
          <a href="https://admin.bokamera.se/#/dashboard" rel="noopener">
            {$_('loginAsAdmin')}
          </a>
        </li>
      </ul>
      <!-- OneTrust Cookies Settings button start -->
      <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
      <!-- OneTrust Cookies Settings button end -->
      <ul><li><a href="/cookies"> {$_('cookiePolicyLinkText')}</a></li></ul>
      <br />
      {$_('copyright')} {getYear(new Date())}   
      <br />
      {$_('allRightsReserved')}
      <a href="/404" class="hidden" aria-hidden="true">404</a>
      <a href="/cookies" class="hidden" aria-hidden="true">Cookies</a>
      <a href="/sitemap.xml" class="hidden" aria-hidden="true">sitemap.xml</a>
      <a href="/robots.txt" class="hidden" aria-hidden="true">robots.txt</a>
      <a href="/mobile-app" class="hidden" aria-hidden="true">Mobile app</a>
      <a href="/payment" class="hidden" aria-hidden="true">Payment</a>
      <a href="/brf" class="hidden" aria-hidden="true">brf</a>
      <a href="/laundry" class="hidden" aria-hidden="true">Laundry</a>
    </div>
  </div>
</footer>

<div class={pagesWithNoBookDemo.includes($page.url.pathname) ? 'hidden' : ''}> 
  <BookDemoWithButton />
</div>